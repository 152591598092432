import type { FC } from 'react';
import React, { Suspense, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import config from 'config';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Titled } from 'react-titled';
import { SnackbarProvider } from 'notistack';
import queryString from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GlobalErrorBoundary from 'GlobalErrorBoundary';
import { queryClient } from 'lib/react-query';
import AuthRequired from 'lib/auth/AuthRequired';
import ProgressSkeleton from 'components/layout/ProgressSkeleton';
import Login from 'pages/login';
import { Layout } from 'components/layout';
import theme from './theme';
import RulesPage from 'pages/rules';
import UsersPage from './pages/users';
import RuleDetailsPage from './pages/rules/RuleDetailsPage';
import EditRulePage from './pages/rules/EditRulePage';
import MetricsPage from 'pages/metrics';
import { MetricsEdit, MetricsTable } from 'features/metrics';
import DuplicateRulePage from './pages/rules/DuplicateRulePage';
import RuleDuplicatePage from 'pages/rules/RuleDuplicatePage';
import { EmergencyProvider } from 'features/emergency';
import RuleEditPage from 'pages/rules/RuleEditPage';
import LogsPage from 'pages/logs';
import LoginAutoTest from 'pages/login-autotest';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: config.COGNITO_DOMAIN,
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN ?? `${window.location.origin}/`,
      redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT ?? `${window.location.origin}/`,
      responseType: 'code',
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

broadcastQueryClient({
  queryClient,
});

const clearCache = async () => {
  queryClient.clear();
  await persister.removeClient();
};

const Providers: FC = () => {
  // Add manually cache clear as PersistQueryClientProvider used with broadcastQueryClient in pair to share cache between tabs
  // will no longer clear cache after page refresh but allow user to clear all cache by reloading page
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('beforeunload', clearCache);
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener('beforeunload', clearCache);
    };
  }, []);

  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: (str) => queryString.parse(str, { arrayFormat: 'comma' }),
        objectToSearchString: (input) => queryString.stringify(input, { arrayFormat: 'comma' }),
      }}
    >
      <CssBaseline />
      <Authenticator.Provider>
        <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
          <ThemeProvider theme={theme}>
            <GlobalErrorBoundary>
              <SnackbarProvider
                autoHideDuration={1000}
                maxSnack={5}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                preventDuplicate
              >
                <EmergencyProvider>
                  <Suspense fallback={<ProgressSkeleton />}>
                    <Outlet />
                  </Suspense>
                </EmergencyProvider>
              </SnackbarProvider>
            </GlobalErrorBoundary>
          </ThemeProvider>
          <ReactQueryDevtools />
        </PersistQueryClientProvider>
      </Authenticator.Provider>
    </QueryParamProvider>
  );
};

const router = createBrowserRouter([
  {
    element: (
      <Titled title="AutoRules">
        <Providers />
      </Titled>
    ),
    children: [
      {
        path: '/rules',
        children: [
          {
            index: true,
            path: '',
            element: (
              <AuthRequired>
                <Titled title="Rules">
                  <Layout containerSx={{ px: 0 }}>
                    <RulesPage />
                  </Layout>
                </Titled>
              </AuthRequired>
            ),
          },
          {
            path: 'details/:ruleId',
            element: (
              <AuthRequired>
                <Titled title="Rule details">
                  <Layout>
                    <RuleDetailsPage />
                  </Layout>
                </Titled>
              </AuthRequired>
            ),
          },
          {
            path: 'edit_form/:ruleId',
            element: (
              <AuthRequired>
                <Titled title="Edit rule">
                  <Layout>
                    <EditRulePage />
                  </Layout>
                </Titled>
              </AuthRequired>
            ),
          },
          {
            path: 'edit/:ruleId',
            element: (
              <AuthRequired>
                <Titled title="Edit rule">
                  <Layout>
                    <RuleEditPage />
                  </Layout>
                </Titled>
              </AuthRequired>
            ),
          },
          {
            path: 'duplicate_form/:ruleId',
            element: (
              <AuthRequired>
                <Titled title="Duplicate rule">
                  <Layout>
                    <DuplicateRulePage />
                  </Layout>
                </Titled>
              </AuthRequired>
            ),
          },
          {
            path: 'duplicate/:ruleId',
            element: (
              <AuthRequired>
                <Titled title="Duplicate rule">
                  <Layout>
                    <RuleDuplicatePage />
                  </Layout>
                </Titled>
              </AuthRequired>
            ),
          },
        ],
      },
      {
        path: '/metrics',
        element: (
          <AuthRequired>
            <Titled title="Metrics">
              <Layout>
                <MetricsPage />
              </Layout>
            </Titled>
          </AuthRequired>
        ),
        children: [
          {
            index: true,
            element: <MetricsTable />,
          },
          {
            path: 'edit',
            element: <MetricsEdit />,
          },
        ],
      },
      {
        path: '/login',
        element: (
          <Titled title="Login">
            <Login />
          </Titled>
        ),
      },
      {
        path: '/login/autotest',
        element: (
          <Titled title="Login AutoTest">
            <LoginAutoTest />
          </Titled>
        ),
      },
      {
        path: '/users',
        element: (
          <AuthRequired>
            <Titled title="Users">
              <Layout>
                <UsersPage />
              </Layout>
            </Titled>
          </AuthRequired>
        ),
      },
      {
        path: '/logs',
        element: (
          <AuthRequired>
            <Titled title="History">
              <Layout>
                <LogsPage />
              </Layout>
            </Titled>
          </AuthRequired>
        ),
      },
      { path: '*', element: <Navigate to="/rules" replace /> },
    ],
  },
]);

const App: FC = () => (
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

export default App;
